import React, { useState, useEffect } from 'react'
import { getHotMeme } from '../../../redux/actions/home'
import { formatNumber } from './formatNumber'
import gmgnLogo from './gmgn-logo.png'
import './index.scss'

const chainConfigs = {
  sol: {
    name: 'SOL'
  },
  base: {
    name: 'Base'
  },
  eth: {
    name: 'ETH'
  }
}

export const Meme = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)

  const [activeTab, setActiveTab] = useState(data?.[0])
  useEffect(() => {
    setActiveTab(data?.sol)
  }, [data])

  useEffect(() => {
    setLoading(true)
    getHotMeme().then(res => {
      if (res.code !== 1) return
      const transformedData = res.obj.reduce((acc, chain) => {
        acc[chain.chainName.toLowerCase()] = {
          name: chainConfigs[chain.chainName.toLowerCase()].name,
          logo: chain.chainLogo,
          data: chain.rankList
        }
        return acc
      }, {})
      setData(transformedData)
      setLoading(false)
    }).catch((err) => {
      console.error(err.message || '获取热门Meme失败')
    }).finally(() => {
      setLoading(false)
    })
  }, [])

  return (
    <div className="meme-box">
      <div className='meme-box-title'>
        <h3>
            热门MEME
        </h3>
      </div>
      <div className='meme-box-title-tab'>
        {Object.values(data).map((item) => (
          <div key={item.name}
            className={`meme-box-title-tab-item ${activeTab?.name === item.name ? 'active' : ''}`}
            onClick={() => setActiveTab(item)}
          >
            <img src={item.logo} alt={item.name} />
            <span>{item.name}</span>
          </div>
        ))}
      </div>

      <div className='meme-box-content'>
        <div className='table-header'>
            <div className='table-header-item'>
                名称
            </div>
            <div className='table-header-item'>
                市值
            </div>
            <div className='table-header-item'>
                1h 交易数
            </div>
        </div>

        {/* 列表 */}
        {loading && Array.from({ length: 6 }).map((_, index) => (
          <div key={index} style={{ height: '32px', marginTop: '20px', background: 'rgba(0, 0, 0, 0.02)' }} />
        ))}

        {!loading && activeTab?.data?.map((item, index) => (
          <div className='meme-box-content-item' key={index}>
            <div className='coin-info'>
              <div className='meme-box-content-item-img'>
                <div className='coin-img'>
                  { item.logo ? <img src={item.logo} alt="coin" /> : <div className='coin-img-default'>{item.symbol?.substring(0, 2)?.toUpperCase()}</div>}
                </div>
                <div className='chain-logo'>
                  <img src={activeTab.logo} alt="logo" />
                </div>
              </div>
              <span>{item.symbol}</span>
            </div>
            <div className='market-cap'>${formatNumber(item.marketcap)}</div>
            <div className='trade-volume'>{formatNumber(item.swaps)}</div>
          </div>
        ))}
      </div>

      <div className='meme-box-footer'>
        <a href='https://gmgn.ai/?ref=y5RUs31x&chain=sol' target='_blank' rel='noreferrer'>
          <span>Powered by：</span>
          <img src={gmgnLogo} alt="gmgn" />
        </a>
      </div>
    </div>
  )
}

export default Meme
