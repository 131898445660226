export const formatNumber = (number, decimals = 2) => {
  // 处理空值或无效输入
  if (number === null || number === undefined || number === '') {
    return '0'
  }

  // 将输入转换为数字
  let num = typeof number === 'string' ? parseFloat(number.replace(/[^0-9.-]/g, '')) : number

  // 处理非数字情况
  if (isNaN(num)) {
    return '0'
  }

  const abs = Math.abs(num)

  // 大于等于1000的数使用 K、M、B 格式
  if (abs >= 1.0e+9) {
    return (num / 1.0e+9).toFixed(decimals) + 'B'
  }
  if (abs >= 1.0e+6) {
    return (num / 1.0e+6).toFixed(decimals) + 'M'
  }
  if (abs >= 1.0e+3) {
    return (num / 1.0e+3).toFixed(decimals) + 'K'
  }

  // 小于1000的数保持原样，只处理小数位数
  return num.toFixed(decimals)
}

export default formatNumber
